




















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class BeatUploadedFiles extends Vue {
    @Prop({ type: Object }) beat: IBeatProducer
}
