
























































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import debounce from 'lodash.debounce'

import { NUM_OF_BEATS_PER_PAGE, BeatStore } from '#/store/beat'

import BeatTableItem from '#/components/BeatTableItem.vue'
import VLoadSpinner from '~/components/VLoadSpinner.vue'
import Pagination from '#/components/Pagination.vue'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { updateBeat, fetchBeats, fetchFilteredBeats } from '#/api/beat'
import { ILimitOffsetParams } from '~/interfaces/interface-utils'
import { UploadType } from '~/store/upload'

enum UpdateBeatAction {
    DeleteBeat,
    DeleteTaggedFile,
    DeleteWav,
    DeleteTrackout,
    DeleteArtwork
}

@Component({ components: { BeatTableItem, VLoadSpinner, Pagination } })
export default class BeatsCatalogPage extends Vue {
    @Prop({ type: Number }) pageIndex: number

    UpdateBeatAction = UpdateBeatAction
    UploadType = UploadType
    isLoading = true
    numberOfPages = 1
    beats: IBeatProducer[] = []
    searchKeyword = ''

    @BeatStore.State('selectedBeats') selectedBeats: IBeat[]
    @BeatStore.Mutation('TOGGLE_FULL_SELECT_DESELECT') TOGGLE_FULL_SELECT_DESELECT: (beats: IBeatProducer[]) => void
    @BeatStore.Mutation('SET_OR_RESET_SELECTED_BEATS') SET_OR_RESET_SELECTED_BEATS: (beat: IBeatProducer) => void
    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

    makeCall = debounce(function(value: string) {
        // @ts-ignore
        this.fetchFilteredBeats(value)
    }, 700)

    async fetchBeats({ limit, offset }: ILimitOffsetParams) {
        this.isLoading = true
        try {
            const data = await fetchBeats({ limit, offset })
            // grab the number of pages only on first fetch
            if (data.count) {
                let numberOfPages = Math.trunc(data.count / NUM_OF_BEATS_PER_PAGE)
                // if the number isn't even, add 1 page to show the remaining beats
                if (data.count > NUM_OF_BEATS_PER_PAGE && data.count % NUM_OF_BEATS_PER_PAGE !== 0) numberOfPages++
                this.numberOfPages = numberOfPages
            }
            this.beats = data.results
        } finally {
            this.isLoading = false
        }
    }

    async fetchFilteredBeats() {
        this.isLoading = true
        try {
            if (this.searchKeyword.length > 0) {
                // if user has entered values in search
                const response = await fetchFilteredBeats(this.searchKeyword)
                this.beats = response
                this.numberOfPages = 0
            } else {
                // if user has deleted values from search, making it an empty string
                this.fetchBeats({ limit: 20, offset: 0 })
            }
        } finally {
            this.isLoading = false
        }
    }

    fetchNewBeats(offset: number, pageIndex: number) {
        this.fetchBeats({ limit: NUM_OF_BEATS_PER_PAGE, offset })
        this.$router.push({ name: 'BeatsCatalog', params: { pageIndex: pageIndex.toString() } })
    }

    updateBeat(actionToTake: UpdateBeatAction) {
        const getIndexOfBeat = (beatToFindId: number) => this.beats.findIndex(beat => beat.id === beatToFindId)

        Promise.all(
            this.selectedBeats.map(async beat => {
                try {
                    switch (actionToTake) {
                        case UpdateBeatAction.DeleteBeat:
                            updateBeat({ id: beat.id, deleted: true })
                            this.beats.splice(getIndexOfBeat(beat.id), 1)
                            break
                        case UpdateBeatAction.DeleteTaggedFile:
                            updateBeat({ id: beat.id, tagged_file_name_thecharts: '' })
                            this.beats[getIndexOfBeat(beat.id)].tagged_file_name_thecharts = ''
                            break
                        case UpdateBeatAction.DeleteWav:
                            updateBeat({ id: beat.id, wav_file_name: '' })
                            this.beats[getIndexOfBeat(beat.id)].wav_file_name = ''
                            break
                        case UpdateBeatAction.DeleteTrackout:
                            updateBeat({ id: beat.id, trackout_file_name: '', trackout_external_url: '' })
                            this.beats[getIndexOfBeat(beat.id)].trackout_file_name = ''
                            this.beats[getIndexOfBeat(beat.id)].trackout_external_url = ''
                            break
                        case UpdateBeatAction.DeleteArtwork:
                            updateBeat({ id: beat.id, image_url: '' })
                            this.beats[getIndexOfBeat(beat.id)].image_url = ''
                            break
                    }
                    this.SET_OR_RESET_SELECTED_BEATS(null)
                    this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
                } catch (err) {
                    this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
                }
            })
        )
    }

    openConfirmActionModal(actionToTake: UpdateBeatAction) {
        this.SET_ACTIVE_MODAL({ modal: ModalType.ConfirmAction, callback: () => this.updateBeat(actionToTake) })
    }

    @Watch('searchKeyword')
    onSearchKeywordChange(newValue: string, oldValue: string) {
        if (newValue.length >= 2 || newValue.length < oldValue.length) this.makeCall(newValue)
    }

    created() {
        this.fetchBeats({ limit: NUM_OF_BEATS_PER_PAGE, offset: (this.pageIndex - 1) * NUM_OF_BEATS_PER_PAGE })
    }
}
