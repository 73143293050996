





































import Vue from 'vue'
import { Prop, Component, Mixins, Watch } from 'vue-property-decorator'
import { updateBeat } from '#/api/beat'
import BeatUploadedFiles from '#/components/BeatUploadedFiles.vue'
import { BeatStore } from '#/store/beat'
import { AudioFileType } from '~/const/audio-file-type'

@Component({ components: { BeatUploadedFiles } })
export default class BeatTableItem extends Vue {
    @Prop({ type: Object }) beat: IBeatProducer
    AudioFileType = AudioFileType

    showShareLinkModal = false
    showDlModal = false
    tooltip1 = "Beat can't be released until all files are uploaded"
    tooltip2 = "Beat can't be released until all files are uploaded"

    @BeatStore.State('selectedBeats') selectedBeats: IBeatProducer[]

    get shareLinkUrl() {
        return `https://thecharts.com/beat/${this.beat.id}`
    }

    get demoDownload() {
        return this.beat.demo_download_allowed
    }
    // patch function to update value
    set demoDownload(value) {
        updateBeat({ id: this.beat.id, demo_download_allowed: value })
    }

    get checkIfSelected() {
        // returns true if this beat is selected
        return this.selectedBeats.some(item => item.id === this.beat.id)
    }
    set checkIfSelected(beat) {
        // adds beat to the selected beats array if you select it
        this.$store.commit('beat/SET_OR_RESET_SELECTED_BEATS', this.beat)
    }

    @BeatStore.Action('downloadFileType') downloadFileType: ({ beatId, fileType }: { beatId: IBeat['id']; fileType: AudioFileType }) => void

    closeModals() {
        this.showShareLinkModal = false
        this.showDlModal = false
    }

    toggleDlModal() {
        this.showDlModal = !this.showDlModal
    }

    toggleShareLinkModal() {
        this.showShareLinkModal = !this.showShareLinkModal
    }

    copyShareLink() {
        // @ts-ignore
        this.$refs.shareLinkUrl.select()
        document.execCommand('copy')
    }
}
